import { useQuery } from '@apollo/client';
import { SITE_QUERY } from '../queries/site';
import { GetSiteQuery, GetSiteQueryVariables } from '../types';

export const useSiteQuery = () => {
  return useQuery<GetSiteQuery, GetSiteQueryVariables>(SITE_QUERY, {
    fetchPolicy: 'network-only',
    ssr: true
  });
};
