import { createGlobalStyle } from 'styled-components';

export const WleTinholtFonts = createGlobalStyle`
  :root {
    --heading-font-family: 'Bitter', sans-serif;
  }
  
  @font-face {
    font-family: 'Bitter';
    font-weight: 400;
    src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/tinholt-fonts/Bitter-VariableFont_wght.ttf')
      format('truetype');
  }
  @font-face {
    font-family: 'Bitter';
    font-weight: 400;
    font-style: italic;
    src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/tinholt-fonts/Bitter-Italic-VariableFont_wght.ttf')
      format('truetype');
  }

  * {
    -webkit-font-smoothing: antialiased;
  }
`;
