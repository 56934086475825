import fetch from 'cross-fetch';
import { HttpLink } from '@apollo/client';
import { GRAPHQL_ENDPOINT } from '../../../global';
import { getDevHeaders } from '../../../../common/utils/getDevHeaders';

export const httpLink = new HttpLink({
  uri: CLIENT ? GRAPHQL_ENDPOINT : '',
  credentials: 'include',
  headers: { ...getDevHeaders() },
  fetch
});
