import * as React from 'react';
export const CloseIcon = () => (
  <svg
    className="close-icon"
    width="22"
    height="20"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L13.5 13.5M25 25L13.5 13.5M13.5 13.5L25 2L2 25"
      stroke="white"
      strokeWidth="3.45"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
