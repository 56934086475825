import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { useAnimation } from '../../hooks/useAnimation';

export enum Animation {
  FromTop = 'fromtop',
  FromBottom = 'frombottom',
  FromLeft = 'fromleft',
  FromRight = 'fromright'
}

const StyledAnimation = styled(animated.div)``;

export interface ICommonAnimationProps {
  start?: boolean;
  delay?: number;
  duration?: number;
  animation?: Animation;
  immediate?: boolean;
  onAnimationEnd?: () => void;
}

interface IAnimateInput extends PropsWithChildren, ICommonAnimationProps {
  className?: string;
}

export const Animate: React.FC<IAnimateInput> = ({
  className = '',
  start,
  delay,
  duration,
  animation,
  immediate = false,
  onAnimationEnd,
  children
}) => {
  const { styles } = useAnimation({
    start,
    delay,
    duration,
    immediate,
    onAnimationEnd,
    animation
  });

  return (
    <StyledAnimation className={`animate-wrapper ${className}`} style={styles}>
      {children}
    </StyledAnimation>
  );
};

export default Animate;
