import * as React from 'react';

export const useIsPrint = (cb?: () => void) => {
  const [isPrint, setIsPrint] = React.useState(false);

  React.useEffect(() => {
    if (isPrint && typeof cb === 'function') cb();
  }, [isPrint]);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const mediaQueryList = window.matchMedia('print');
    if (mediaQueryList.matches) setIsPrint(true);
  }, []);

  return isPrint;
};
