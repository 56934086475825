import { v1 as uuid } from 'uuid';
import { IAddToastArgs, IRemoveToastArgs, IToast, IToastContext } from './ToastContext';

export enum ToastAction {
  Add = 'add',
  Remove = 'remove',
  RemoveFirst = 'removefirst'
}

type Add = {
  type: ToastAction.Add;
  data: IAddToastArgs;
};

type Remove = {
  type: ToastAction.Remove;
  data: IRemoveToastArgs;
};

type RemoveFirst = {
  type: ToastAction.RemoveFirst;
};

type Actions = Add | Remove | RemoveFirst;

export function ToastReducer(state: IToastContext, action: Actions) {
  switch (action.type) {
    case ToastAction.Add:
      const toast = action.data as IToast;
      return {
        ...state,
        toasts: [...state.toasts, { ...toast, id: uuid(), show: true }]
      };
    case ToastAction.Remove:
      return {
        ...state,
        toasts: state.toasts.filter((item) => item.id !== action.data.id)
      };
    case ToastAction.RemoveFirst:
      const toasts = [...state.toasts];
      toasts.shift();
      return { ...state, toasts };
    default:
      return state;
  }
}
