import { fromPromise, makeVar } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { replacePlaceholders } from '@visma-real-estate-solutions/rt_utils_strings';
import { client } from '..';

import { setAccessToken } from '../../../../common/utils/token';
import { REFRESH_ENDPOINT } from '../../../global';

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback: any) => callback());
  pendingRequests = [];
};

export const estateIdVar = makeVar('');

const refreshToken = async () => {
  const url = replacePlaceholders(REFRESH_ENDPOINT as string, {
    estateId: estateIdVar()
  }) as string;
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    referrerPolicy: 'unsafe-url'
  })
    .then(async (data) => {
      if (data.status !== 200) {
        pendingRequests = [];
        client.getObservableQueries().forEach((observableQuery) => {
          observableQuery.refetch();
        });
        return;
      } else {
        const contentType = data.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const { token } = await data.json();
          setAccessToken(token);
          resolvePendingRequests();
          return token;
        }
      }
    })
    .catch(() => {
      pendingRequests = [];
      return;
    })
    .finally(() => {
      isRefreshing = false;
    });
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (networkError?.message === 'Failed to fetch') {
      networkError.message = 'Could not establish contact with service';
    }
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            if (err.extensions.refresh) {
              isRefreshing = true;
              let forward$ = null;
              if (isRefreshing) {
                forward$ = fromPromise(refreshToken());
              } else {
                forward$ = fromPromise(
                  new Promise((resolve) => {
                    pendingRequests.push(() => resolve(true));
                  })
                );
              }
              return forward$.flatMap(() => forward(operation));
            }
        }
        return forward(operation);
      }
    }
  }
);
