import { createGlobalStyle } from 'styled-components';

export const WleEiendomsmeglerneFonts = createGlobalStyle`
  :root {
    --heading-font-family: 'PlayfairDisplay', sans-serif;
  }

    @font-face {
        font-family: 'Open Sans';
        font-weight: 400;
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/eiendomsmeglerne-fonts/OpenSans-VariableFont_wdth,wght.ttf')
            format('truetype');
            font-display: swap;
     }
  
    @font-face {
        font-family: 'Open Sans';
        font-weight: 400;
        font-style: italic;
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/eiendomsmeglerne-fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf')
            format('truetype');
            font-display: swap;
    }

    @font-face {
        font-family: 'PlayfairDisplay';
        font-weight: 400;
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/eiendomsmeglerne-fonts/PlayfairDisplay-VariableFont_wght.ttf')
            format('truetype');
            font-display: swap;
    }

    @font-face {
        font-family: 'PlayfairDisplay';
        font-weight: 400;
        font-style: italic;
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/eiendomsmeglerne-fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf')
            format('truetype');
            font-display: swap;
    }

    * {
        -webkit-font-smoothing: antialiased;
    }
`;
