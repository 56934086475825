import React, { PropsWithChildren, useReducer } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { ModalAction, ModalReducer } from './ModalReducer';

export interface IModalOpenArgs {
  content?: any;
  delay?: number;
  hideCloseButton?: boolean;
  hideOverlay?: boolean;
  overlayOpacity?: number;
  initialAnimation?: boolean;
  onClose?: (args: { isMobile: boolean }) => void;
}

export interface IModalContext {
  show: boolean;
  delay: number;
  content: any;
  hideCloseButton: boolean;
  hideOverlay: boolean;
  overlayOpacity: number;
  initialAnimation: boolean;
  openModal: (args: IModalOpenArgs) => void;
  closeModal: () => void;
  onClose: (args: { isMobile: boolean }) => void;
}

const defaultContext = {
  show: false,
  delay: 0,
  content: null,
  hideCloseButton: false,
  hideOverlay: false,
  overlayOpacity: 0.5,
  initialAnimation: true,
  openModal: () => {},
  closeModal: () => {},
  onClose: () => {}
};

export const ModalContext = React.createContext<IModalContext>(defaultContext);

export const ModalContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useIsMobile();
  const [state, dispatch] = useReducer(ModalReducer, defaultContext);

  const openModal = (args: IModalOpenArgs) => {
    if (!args.content) console.error('no content defined for modal');
    dispatch({ type: ModalAction.Open, data: args });
  };

  const closeModal = () => {
    dispatch({ type: ModalAction.Close, data: defaultContext });
    if (typeof state.onClose === 'function') state.onClose({ isMobile });
  };

  return (
    <ModalContext.Provider value={{ ...state, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
