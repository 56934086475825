import { useEffect, useState } from 'react';

interface IUseElementDimension {
  ref: { current: any };
  disable?: boolean;
}

export const useElementDimension = (args: IUseElementDimension) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (args.disable) return;
    const handleGetDimensions = () => {
      const { width, height } = args.ref?.current?.getBoundingClientRect();
      if (width !== dimensions.width && height !== dimensions.height) {
        setDimensions({ width, height });
      }
    };
    handleGetDimensions();
  }, [args.ref?.current, args.disable]);
  return dimensions;
};
