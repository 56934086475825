import { ApolloClient, ApolloLink, concat } from '@apollo/client';

import { typeDefs } from '../typeDefs';
import { authLink } from './links/auth';
import { errorLink } from './links/error';
import { retryLink } from './links/retry';
import { httpLink } from './links/http';
import { getApolloCache } from '../../../common/graphql/client/cache';

const cache = getApolloCache();
if (CLIENT) cache.restore(window.__APOLLO_STATE__);

const link = CLIENT
  ? ApolloLink.from([retryLink, errorLink, concat(authLink, httpLink)])
  : undefined;

export const client = new ApolloClient({
  link,
  name: 'rt_ds',
  cache,
  typeDefs,
  ssrForceFetchDelay: 50,
  connectToDevTools: true
});
