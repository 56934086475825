import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';

import { client } from './graphql/client';

import { App } from '../common/App';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';

loadableReady(() => {
  const node = document.getElementById('root') as HTMLElement;
  if (!node) console.error('mount node is not found in document');
  const root = createRoot(node);
  root.render(
    <ApolloProvider client={client}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </ApolloProvider>
  );
});
