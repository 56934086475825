import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { BaseCommonPage } from '../layout/BaseCommonPage';
import { ContactDetails } from '../layout/ContactDetails';
import { BasePageContent } from './Base';
import { ErrorDetails } from './ErrorDetails';
import Button from '../ui/Button';
import { ButtonColorTheme, ButtonVariant } from '../ui/BaseButton';
import ButtonLink from '../ui/ButtonLink';
import styled from 'styled-components';

interface IError extends React.PropsWithChildren {
  message?: string;
  rcid?: string;
}

const CustomErrorSection: React.FC<{ code?: string | null; message: string | null }> = ({
  code,
  message
}) => {
  if (!code || !message) return null;
  return <p>{message}</p>;
};

const Error: React.FC<IError> = ({ message, rcid, children }) => {
  const [searchParams] = useSearchParams();
  const queryStringCode = searchParams.get('code');
  const queryStringUrl = searchParams.get('latest');
  const queryStringErrorMessage = searchParams.get('error_message');

  if (queryStringCode === 'already-approved') {
    return (
      <BaseCommonPage>
        <BasePageContent>
          <h1>Salgsoppgaven er godkjent</h1>
          {queryStringUrl ? (
            <>
              {' '}
              <p>Du kan se godkjent versjon her: </p>
              <ButtonGroup>
                <ButtonLink
                  variant={ButtonVariant.Solid}
                  colorTheme={ButtonColorTheme.Secondary}
                  href={queryStringUrl}
                  target="_blank"
                >
                  Se godkjent versjon
                </ButtonLink>
              </ButtonGroup>
            </>
          ) : null}
          <CustomErrorSection code={queryStringCode} message={queryStringErrorMessage} />
          <ContactDetails />
          <ErrorDetails rcid={rcid} />
        </BasePageContent>
      </BaseCommonPage>
    );
  }

  return (
    <BaseCommonPage>
      <BasePageContent>
        <h1>Noe gikk galt</h1>
        {message ? (
          <p>
            <b>Error:</b> {message}
          </p>
        ) : null}
        {children ? (
          <span style={{ display: 'block', padding: '1em 0' }}>{children}</span>
        ) : null}
        <CustomErrorSection code={queryStringCode} message={queryStringErrorMessage} />
        <ContactDetails />
        <ErrorDetails rcid={rcid} />
      </BasePageContent>
    </BaseCommonPage>
  );
};

export default Error;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;
