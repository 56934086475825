import { InMemoryCache } from '@apollo/client';

export const getApolloCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Comment: {
        fields: {
          creating: {
            read: (creating) => !!creating
          }
        }
      },
      DsSettingsStatus: {
        merge: (existing, incoming, { mergeObjects }) => {
          return mergeObjects(existing, incoming);
        }
      },
      Query: {
        fields: {
          dsSettings: {
            merge: (existing, incoming, { mergeObjects }) => {
              return mergeObjects(existing, incoming);
            }
          },
          dsComments: {
            merge: (existing, incoming, { mergeObjects }) => {
              return mergeObjects(existing, incoming);
            }
          },
          estate: {
            merge: (existing, incoming, { mergeObjects }) => {
              return mergeObjects(existing, incoming);
            }
          }
        }
      }
    }
  });
};
