import { useEffect } from 'react';
import { useSpring } from '@react-spring/web';

import { Animation, ICommonAnimationProps } from '../components/layout/Animate';
import { useIsPrint } from './useIsPrint';

interface IUseAnimationArgs extends ICommonAnimationProps {
  duration?: number;
}

export const useAnimation = ({
  delay,
  start,
  animation,
  immediate,
  onAnimationEnd
}: IUseAnimationArgs) => {
  const isPrint = useIsPrint();
  let animationStartConfig: { [key: string]: any } = {};
  let animationEndConfig: { [key: string]: any } = {};
  switch (animation) {
    case Animation.FromTop:
      animationStartConfig = { opacity: 0, transform: 'translate3d(0, -1em, 0)' };
      animationEndConfig = { opacity: 1, transform: 'translate3d(0, 0em, 0)' };
      break;
    case Animation.FromRight:
      animationStartConfig = { opacity: 0, transform: 'translate3d(2em, 0, 0)' };
      animationEndConfig = { opacity: 1, transform: 'translate3d(0em, 0, 0)' };
      break;
    case Animation.FromLeft:
      animationStartConfig = { opacity: 0, transform: 'translate3d(-2em, 0, 0)' };
      animationEndConfig = { opacity: 1, transform: 'translate3d(0em, 0, 0)' };
      break;
    case Animation.FromBottom:
      animationStartConfig = { opacity: 0, transform: 'translate3d(0, 1em, 0)' };
      animationEndConfig = { opacity: 1, transform: 'translate3d(0, 0em, 0)' };
      break;
    default:
      animationStartConfig = { opacity: 0 };
      animationEndConfig = { opacity: 1 };
      break;
  }

  const [styles, api] = useSpring(() => animationStartConfig);

  useEffect(() => {
    if (isPrint || immediate) {
      api.start({
        ...animationEndConfig,
        immediate: true,
        onRest: () => (typeof onAnimationEnd === 'function' ? onAnimationEnd() : null)
      });
    } else if (start || typeof start === 'undefined') {
      api.start({
        ...animationEndConfig,
        delay,
        config: { tension: 170, damping: 1, friction: 26, mass: 1 },
        onRest: () => (typeof onAnimationEnd === 'function' ? onAnimationEnd() : null)
      });
    }
  }, [, start, isPrint, immediate]);

  return { styles };
};
