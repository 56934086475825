import { createGlobalStyle } from 'styled-components';

export const ReleMeglerenFonts = createGlobalStyle`
    :root {
        --heading-font-family: 'AbrilFatface', sans-serif;
        --body-font-family: 'Nudista', sans-serif;
    }

    @font-face {
        font-family: 'AbrilFatface';
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/rele/AbrilFatface-Regular.ttf');
    }

    @font-face {
        font-family: 'Nudista';
        src: url("https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/rele/Nudista.otf");
        font-weight: normal;
    }
    
    @font-face {
        font-family: 'Nudista-Bold';
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/rele/Nudista-Bold.otf');
        font-weight: bold;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: var(--heading-font-family) !important;
        letter-spacing: 0;
        font-weight: 400;
        font-style: normal;
    }
    

    * {
        -webkit-font-smoothing: antialiased;
        font-family: var(--body-font-family); 
    }

`;
