import { IModalContext, IModalOpenArgs } from './ModalContext';

export enum ModalAction {
  Open = 'open',
  Close = 'close'
}

type Open = {
  type: ModalAction.Open;
  data: IModalOpenArgs;
};

type Close = {
  type: ModalAction.Close;
  data: IModalContext | IModalOpenArgs;
};

type Actions = Open | Close;

export function ModalReducer(state: IModalContext, action: Actions) {
  switch (action.type) {
    case ModalAction.Open:
      return { ...state, show: true, ...action.data };
    case ModalAction.Close:
      return { ...state, show: false, ...action.data };
    default:
      return state;
  }
}
