import React, { PropsWithChildren, useEffect, useReducer, useRef } from 'react';
import { ToastAction, ToastReducer } from './ToastReducer';

export enum ToastType {
  Success,
  Warning,
  Error
}
export interface IAddToastArgs {
  type: ToastType;
  message: string;
  removeAfterSeconds?: number;
}

export interface IRemoveToastArgs {
  id: string;
}

export interface IToast {
  type: ToastType;
  id: string;
  message: string;
  show: boolean;
  removeAfterSeconds?: number;
}

export interface IToastContext {
  toasts: IToast[];
  addToast: (args: IAddToastArgs) => void;
  removeToast: (args: IRemoveToastArgs) => void;
}

const defaultContext: IToastContext = {
  toasts: [],
  removeToast: () => {},
  addToast: () => {}
};

export const ToastContext = React.createContext<IToastContext>(defaultContext);

export const ToastContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const timeoutRef = useRef<any>();
  const [state, dispatch] = useReducer(ToastReducer, defaultContext);

  const addToast = (args: IAddToastArgs) => {
    dispatch({ type: ToastAction.Add, data: args });
  };

  const removeToast = (args: IRemoveToastArgs) => {
    dispatch({ type: ToastAction.Remove, data: args });
  };

  useEffect(() => {
    if (state.toasts.length > 3) {
      dispatch({ type: ToastAction.RemoveFirst });
    }
  }, [state.toasts]);

  useEffect(() => {
    if (state.toasts.length === 0) return;
    timeoutRef.current = setTimeout(() => {
      dispatch({ type: ToastAction.RemoveFirst });
    }, 5000);
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [state.toasts]);

  return (
    <ToastContext.Provider value={{ ...state, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
};
