import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useTransition } from '@react-spring/web';

import { useToast } from './hooks/useToast';

import { ToastItem } from './ToastItem';

export const Toasts: React.FC = () => {
  const { toasts } = useToast();
  const transitions = useTransition(toasts, {
    from: { opacity: 0, transform: 'translate3d(20px, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(20px, 0, 0)' }
  });

  const ToastWrapper: React.FC = () => {
    return (
      <StyledToastWrapper>
        {transitions((styles, item) => (
          <ToastItem
            key={item.id}
            id={item.id}
            type={item.type}
            animatedProps={styles}
            message={item.message}
          />
        ))}
      </StyledToastWrapper>
    );
  };

  if (CLIENT) {
    const container = document.getElementById('toasts');
    if (!container) {
      return null;
    }
    return createPortal(<ToastWrapper />, container);
  }
  return null;
};

const StyledToastWrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1em;
  width: 100%;
  height: 100dvh;
  padding: 1em 1em 2em 1em;
  pointer-events: none;
  z-index: 10000;

  @media all and (min-width: 60em) {
    padding: 1em;
  }
`;
