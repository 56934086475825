import * as React from 'react';

import { BarLoader } from 'react-spinners';
import { BaseCommonPage } from '../../layout/BaseCommonPage';
import { cssVar } from 'polished';

export const SiteLoader: React.FC<{ text?: string }> = ({
  text = 'Vennligst vent..'
}) => {
  return (
    <BaseCommonPage>
      <div>
        <p style={{ fontSize: '1em', fontWeight: 'bold' }}>{text}</p>
        <BarLoader
          width="10em"
          height="0.3em"
          color={cssVar('--site-loader-color', '#000') as string}
        />
      </div>
    </BaseCommonPage>
  );
};
