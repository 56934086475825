export const CDN_URL =
  typeof window !== 'undefined'
    ? window.__CONFIG__.CDN_URL
    : process
    ? process.env.CDN_URL
    : undefined;

export const DEVELOPMENT =
  typeof window !== 'undefined' ? window.__CONFIG__.DEVELOPMENT : false;
export const GRAPHQL_ENDPOINT =
  typeof window !== 'undefined' ? window.__CONFIG__.GRAPHQL_ENDPOINT : '';
export const REFRESH_ENDPOINT =
  typeof window !== 'undefined' ? window.__CONFIG__.REFRESH_ENDPOINT : '';
