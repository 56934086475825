import * as React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

const StyledButtonLoader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
`;

export const ButtonLoader: React.FC<{ color: string }> = ({ color }) => {
  if (!color) console.error('no color defined for <ButtonLoader />');
  return (
    <StyledButtonLoader>
      <ClipLoader color={color} size="1em" />
    </StyledButtonLoader>
  );
};
