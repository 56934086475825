import * as React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

interface ErrorDetailsProps extends React.PropsWithChildren {
  rcid?: string;
}

export const ErrorDetails: React.FC<ErrorDetailsProps> = ({ rcid, children }) => {
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const location = useLocation();

  return (
    <StyledErrorDetails>
      {!detailsOpen ? (
        <button className="show-more" onClick={() => setDetailsOpen(true)}>
          Vis detaljer
        </button>
      ) : (
        <>
          <p className="error-url" style={{ wordBreak: 'break-all' }}>
            <b>url:</b> {location.pathname}
            {location.search}
          </p>
          <span className="details">{children}</span>
          {rcid ? <p style={{ fontSize: '0.8em' }}>rcid: {rcid}</p> : null}
        </>
      )}
    </StyledErrorDetails>
  );
};

const StyledErrorDetails = styled.div`
  margin-top: 1em;
  text-align: center;

  .show-more {
    appearance: none;
    background: none;
    text-decoration: underline;
    font-size: 0.7rem;
    color: #000;
  }

  .error-url {
    font-size: 0.8rem;
    max-width: 35em;
    text-align: center;
  }

  .details {
    font-size: 0.8rem;
  }
`;
