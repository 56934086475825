import { createGlobalStyle } from 'styled-components';

export const BelevenFonts = createGlobalStyle`
    @font-face {
        font-family: 'Suisse Intl';
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/beleven/SuisseIntl-Medium.otf') format('opentype');
        font-weight: medium;
    }
    @font-face {
        font-family: 'Suisse Intl';
        src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/beleven/SuisseIntl-Regular.otf') format('opentype');
        font-weight: normal;
    }

    :root {
        --heading-font-family: 'Suisse Intl', sans-serif;
        --body-font-family: 'Suisse Intl', sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: var(--heading-font-family) !important;
        letter-spacing: 0;
    }
    

    * {
        -webkit-font-smoothing: antialiased;
        font-family: var(--body-font-family); 
    }

`;
