import React, { PropsWithChildren } from 'react';
import { createGlobalStyle } from 'styled-components';

import { Fonts } from './Fonts';
import { GlobalStyles } from './GlobalStyles';
import { GlobalBrandStyles } from './BrandStyles';
import { createRootStyles } from '../utils/createRootStyles';

interface ThemeProps extends PropsWithChildren {
  styles: { [key: string]: { [key: string]: string } };
  brandId?: string | null | undefined;
}

const RootStyles = createGlobalStyle<{ styles: ThemeProps['styles'] }>`
  :root {
    ${({ styles }) => createRootStyles(styles.root)}
  }
`;

export const Theme: React.FC<ThemeProps> = ({ styles, brandId, children }) => {
  return (
    <>
      <RootStyles styles={styles} />
      <GlobalStyles />
      <Fonts brandId={brandId} />
      <GlobalBrandStyles brandId={brandId} />
      {children}
    </>
  );
};
