import { v4 as uuid } from 'uuid';
import { ApolloLink } from '@apollo/client';
import { getAccessToken } from '../../../../common/utils/token';

export const authLink = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken();
  const { headers } = operation.getContext();
  operation.setContext(() => ({
    headers: {
      ...headers,
      traceId: uuid(),
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  }));
  return forward(operation);
});
