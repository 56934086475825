import * as React from 'react';
import styled from 'styled-components';
import { CloseIcon } from './icons/Close';

const StyledButtonClose = styled.button`
  background: none;
  border: none;
`;

interface IButtonClose extends React.ComponentProps<'button'> {
  icon?: React.ReactNode;
}

export const ButtonClose: React.FC<IButtonClose> = React.memo(
  ({ className, style, icon, onClick }) => {
    return (
      <StyledButtonClose className={className} style={style} onClick={onClick}>
        {icon ? icon : <CloseIcon />}
      </StyledButtonClose>
    );
  }
);
