import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { useElementDimension } from '../../hooks/useElementDimension';
import {
  SolidButtonStyles,
  OutlineButtonStyles,
  UnderlineButtonStyles,
  ButtonVariant,
  ButtonColorTheme,
  BaseButtonIcon
} from './BaseButton';
import { ButtonLoader } from './loaders/ButtonLoader';

const SolidButtonLink = styled.a`
  ${SolidButtonStyles}
`;

const OutlineButtonLink = styled.a`
  ${OutlineButtonStyles}
`;

const UnderlineButtonLink = styled.a`
  ${UnderlineButtonStyles}
`;

interface IButtonLinkProps extends React.ComponentProps<'a'> {
  loading?: boolean;
  variant: ButtonVariant;
  colorTheme: ButtonColorTheme;
  icon?: React.ReactNode;
  hover?: boolean;
}

const ButtonLink: React.FC<IButtonLinkProps> = React.memo(
  ({
    variant,
    colorTheme,
    hover = false,
    icon,
    loading,
    className = '',
    children,
    style,
    ref,
    ...props
  }) => {
    const theme = useTheme();
    const buttonRef = React.useRef(null);
    const { width, height } = useElementDimension({
      ref: buttonRef
    });

    let styles = {};
    if (loading) {
      styles = { ...style, width, height };
    }

    const currentTheme =
      theme?.root?.button[variant.toLowerCase()][colorTheme.toLowerCase()];
    const loaderColor = currentTheme?.loaderColor || '#000';

    switch (variant) {
      case ButtonVariant.Solid:
        return (
          <SolidButtonLink
            className={`button-link ${className}`}
            colorTheme={colorTheme.toLowerCase()}
            hover={hover}
            ref={buttonRef}
            style={styles}
            {...props}
          >
            {loading ? (
              <ButtonLoader color={loaderColor} />
            ) : (
              <>
                {icon ? <BaseButtonIcon>{icon}</BaseButtonIcon> : null}
                <span>{children}</span>
              </>
            )}
          </SolidButtonLink>
        );
      case ButtonVariant.Outline:
        return (
          <OutlineButtonLink
            className={`button-link ${className}`}
            colorTheme={colorTheme.toLowerCase()}
            hover={hover}
            ref={buttonRef}
            style={styles}
            {...props}
          >
            {loading ? (
              <ButtonLoader color={loaderColor} />
            ) : (
              <>
                {icon ? <BaseButtonIcon>{icon}</BaseButtonIcon> : null}
                <span>{children}</span>
              </>
            )}
          </OutlineButtonLink>
        );
      case ButtonVariant.Underline:
        return (
          <UnderlineButtonLink
            className={`button-link ${className}`}
            colorTheme={colorTheme.toLowerCase()}
            hover={hover}
            ref={buttonRef}
            style={styles}
            {...props}
          >
            {icon ? <BaseButtonIcon>{icon}</BaseButtonIcon> : null}
            <span>{children}</span>
          </UnderlineButtonLink>
        );
      default:
        throw new Error('Invalid button variant');
    }
  }
);

export default ButtonLink;
