import * as React from 'react';

export const ContactDetails: React.FC = () => {
  return (
    <p className="contact-details">
      For spørsmål - Send en henvendelse til{' '}
      <b>
        <a href="mailto:helpdesk@reeltime.no">helpdesk@reeltime.no</a>
      </b>
    </p>
  );
};
