import { gql } from '@apollo/client';

export const SITE_QUERY = gql`
  query GetSite {
    layout {
      brandId
      brandName
      favicon
      logo
      allowedOrigins
      style
    }
  }
`;
