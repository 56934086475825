import { gql } from '@apollo/client';

/* Client only schema */

export const typeDefs = gql`
  extend type Comment {
    creating: Boolean! @client
  }

  extend type AuthenticatedResponse {
    redirect: Boolean! @client
  }

  extend type Document {
    selected(dsId: String!): Boolean! @client
  }
`;
