import styled from 'styled-components';

export const BasePageContent = styled.div`
  width: minmax(min-content, 100%);
  padding: 2em;
  background: var(--common-page-background-color, #fff);
  color: var(--common-page-color, #000);

  .contact-details {
    a {
      color: var(--common-page-link-color, #000);
    }
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
