import * as React from 'react';
import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { ButtonClose } from '../../ui/ButtonClose';
import { useToast } from './hooks/useToast';
import { ToastType } from './ToastContext';
import { cssVar } from 'polished';

interface IToastItem {
  type: ToastType;
  id: string;
  animatedProps: any;
  message: string;
}

export const ToastItem: React.FC<IToastItem> = React.memo(
  ({ id, type, animatedProps, message }) => {
    const { removeToast } = useToast();
    return (
      <StyledToastItem style={animatedProps} type={type}>
        {message}
        <ButtonClose className="remove-toast" onClick={() => removeToast({ id })} />
      </StyledToastItem>
    );
  }
);

const StyledToastItem = styled(animated.div)<{ type: ToastType }>`
  display: flex;
  align-items: center;
  gap: 0.5em;
  width: minmax(min-content, 50%);
  padding: 1em 1em 1em 1.5em;
  font-size: 0.9rem;
  border-radius: 0.5em;
  pointer-events: auto;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);

  ${({ type }) => (type === ToastType.Error ? 'background: #9c1111; color: #fff;' : '')}
  ${({ type }) => (type === ToastType.Warning ? 'background: #ff9100; color: #fff;' : '')}
  ${({ type }) =>
    type === ToastType.Success
      ? 'background: var(--toast-background-color,#fff); color: #000;'
      : ''}

  .remove-toast {
    position: relative;
    top: 0.125em;
    right: 0;
    svg {
      width: 1em;
      path {
        ${({ type }) => (type === ToastType.Success ? 'stroke: #000;' : '')}
      }
    }
  }
`;
