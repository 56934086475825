import { createGlobalStyle } from 'styled-components';

export const WleStavlundFonts = createGlobalStyle`
  :root {
    --heading-font-family: 'MavenPro', sans-serif;
  }

  @font-face {
  font-family: 'AdventPro';
  font-weight: 700;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/AdventPro-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'AdventPro';
  font-weight: 600;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/AdventPro-SemiBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'AdventPro';
  font-weight: 500;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/AdventPro-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'AdventPro';
  font-weight: 400;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/AdventPro-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'MavenPro';
  font-weight: 700;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/MavenPro-ExtraBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'MavenPro';
  font-weight: 600;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/MavenPro-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'MavenPro';
  font-weight: 500;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/MavenPro-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'MavenPro';
  font-weight: 400;
  src: url('https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/stavlund-fonts/MavenPro-Regular.ttf')
    format('truetype');
}

  * {
    -webkit-font-smoothing: antialiased;
  }
`;
