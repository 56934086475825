import styled, { css } from 'styled-components';

export enum ButtonVariant {
  Solid = 'Solid',
  Outline = 'Outline',
  Underline = 'Underline'
}

export enum ButtonColorTheme {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary'
}

export const BaseButtonIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.2em;
  margin-right: 0.5em;
`;

interface IButtonProps {
  colorTheme: string;
  hover?: boolean;
  href?: string;
}

export const BaseButton = css<IButtonProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: min-content;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: var(--button-font-weight, 400);
  appearance: none;

  svg {
    max-width: 1.2em;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SolidButtonStyles = css`
  ${BaseButton}
  padding: 0.8em 1em;
  background-color: ${({ colorTheme }) =>
    `var(--button-solid-${colorTheme}-background-color, #000)`};
  color: ${({ colorTheme }) => `var(--button-solid-${colorTheme}-color, #fff)`};
  border-color: ${({ colorTheme }) =>
    `var(--button-solid-${colorTheme}-background-color, #000)`};
  border-width: ${({ colorTheme }) =>
    `var(--button-solid-${colorTheme}-border-width, 0)`};
  border-style: solid;
  border-radius: ${({ colorTheme }) =>
    `var(--button-solid-${colorTheme}-border-radius, var(--button-border-radius, 0))`};

  .button-icon {
    svg {
      path {
        fill: ${({ colorTheme }) => `var(--button-solid-${colorTheme}-icon-color, #fff)`};
      }
    }
  }

  &:hover {
    background-color: ${({ colorTheme }) =>
      `var(--button-solid-${colorTheme}-hover-background-color, var(--button-solid-${colorTheme}-background-color, var(--button-solid-${colorTheme}-background-color, #000)))`};
    color: ${({ colorTheme }) =>
      `var(--button-solid-${colorTheme}-hover-color, var(--button-solid-${colorTheme}-color, var(--button-solid-${colorTheme}-color, #fff)))`};

    border-color: ${({ colorTheme }) =>
      `var(--button-solid-${colorTheme}-hover-background-color, var(--button-solid-${colorTheme}-background-color, var(--button-solid-${colorTheme}-background-color, #000)))`};

    border-width: ${({ colorTheme }) =>
      `var(--button-solid-${colorTheme}-hover-border-width, var(--button-solid-${colorTheme}-border-width, var(--button-solid-${colorTheme}-border-width, 0)))`};

    border-radius: ${({ colorTheme }) =>
      `var(--button-solid-${colorTheme}-hover-border-radius, var(--button-solid-${colorTheme}-border-radius, var(--button-solid-${colorTheme}-border-radius, var(--button-border-radius, 0))))`};

    .button-icon {
      svg {
        path {
          fill: ${({ colorTheme }) =>
            `var(--button-solid-${colorTheme}-hover-icon-color, var(--button-solid-${colorTheme}-icon-color, #fff))`};
        }
      }
    }
  }
`;

export const OutlineButtonStyles = css`
  ${BaseButton}
  padding: 0.8em 1em;
  background-color: transparent;
  color: ${({ colorTheme }) => `var(--button-outline-${colorTheme}-color, #fff)`};
  border-style: ${({ colorTheme }) =>
    `var(--button-outline-${colorTheme}-border-style, solid)`};
  border-color: ${({ colorTheme }) =>
    `var(--button-outline-${colorTheme}-border-color, #000)`};
  border-width: ${({ colorTheme }) =>
    `var(--button-outline-${colorTheme}-border-width, 0.1em)`};
  border-radius: ${({ colorTheme }) =>
    `var(--button-outline-${colorTheme}-border-radius, var(--button-border-radius, 0))`};

  .button-icon {
    svg {
      path {
        fill: ${({ colorTheme }) =>
          `var(--button-outline-${colorTheme}-iconColor, #000)`};
      }
    }
  }

  &:hover {
    background-color: ${({ colorTheme }) =>
      `var(--button-outline-${colorTheme}-hover-background-color, transparent)`};
    color: ${({ colorTheme }) =>
      `var(--button-outline-${colorTheme}-hover-color, var(--button-outline-${colorTheme}-color, var(--button-outline-${colorTheme}-color, #fff)))`};

    border-style: ${({ colorTheme }) =>
      `var(--button-outline-${colorTheme}-hover-border-style, var(--button-outline-${colorTheme}-border-style, var(--button-outline-${colorTheme}-border-style, solid)))`};

    border-color: ${({ colorTheme }) =>
      `var(--button-outline-${colorTheme}-hover-border-color, var(--button-outline-${colorTheme}-border-color, var(--button-outline-${colorTheme}-border-color, #000)))`};

    border-width: ${({ colorTheme }) =>
      `var(--button-outline-${colorTheme}-hover-border-width, var(--button-outline-${colorTheme}-border-width, var(--button-outline-${colorTheme}-border-width, 0.1em)))`};

    border-radius: ${({ colorTheme }) =>
      `var(--button-outline-${colorTheme}-hover-border-radius, var(--button-outline-${colorTheme}-border-radius, var(--button-outline-${colorTheme}-border-radius, var(--button-border-radius, 0))))`};

    .button-icon {
      svg {
        path {
          fill: ${({ colorTheme }) =>
            `var(--button-outline-${colorTheme}-hover-iconColor, var(--button-outline-${colorTheme}-icon-color, var(--button-outline-${colorTheme}-iconColor, #000)))`};
        }
      }
    }
  }
`;

export const UnderlineButtonStyles = css`
  ${BaseButton}
  width: max-content;
  padding-top: 0.8em;
  padding-bottom: 0.2em;
  margin-bottom: 0.6em;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  color: ${({ colorTheme }) => `var(--button-underline-${colorTheme}-color, #fff)`};
  border-bottom-style: ${({ colorTheme }) =>
    `var(--button-underline-${colorTheme}-border-style, solid)`};
  border-bottom-color: ${({ colorTheme }) =>
    `var(--button-underline-${colorTheme}-border-color, #000)`};
  border-bottom-width: ${({ colorTheme }) =>
    `var(--button-underline-${colorTheme}-border-width, 0.1em)`};

  .button-icon {
    svg {
      path {
        fill: ${({ colorTheme }) =>
          `var(--button-underline-${colorTheme}-icon-color, #000)`};
      }
    }
  }

  &:hover {
    background-color: ${({ colorTheme }) =>
      `var(--button-underline-${colorTheme}-hover-background-color, transparent)`};
    color: ${({ colorTheme }) =>
      `var(--button-underline-${colorTheme}-hover-color, var(--button-underline-${colorTheme}-color, #fff)`};

    border-bottom-style: ${({ colorTheme }) =>
      `var(--button-underline-${colorTheme}-hover-border-style, var(--button-underline-${colorTheme}-border-style, solid))`};

    border-bottom-color: ${({ colorTheme }) =>
      `var(--button-underline-${colorTheme}-hover-border-color, var(--button-underline-${colorTheme}-border-color, #000))`};

    border-bottom-width: ${({ colorTheme }) =>
      `var(--button-underline-${colorTheme}-hover-border-width, var(--button-underline-${colorTheme}-border-width, 0.1em))`};

    .button-icon {
      svg {
        path {
          fill: ${({ colorTheme }) =>
            `var(--button-underline-${colorTheme}-hover-icon-color, var(--button-underline-${colorTheme}-icon-color, #000))`};
        }
      }
    }
  }
`;
