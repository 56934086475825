import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { GlobalStyles } from '../assets/GlobalStyles';
import { Animate } from './Animate';

export const BaseCommonPage: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Animate>
      <GlobalStyles />
      <StyledPage className="common-page">{children}</StyledPage>
    </Animate>
  );
};

const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1em;
  width: 100cqw;
  min-height: 100cqh;
  background: var(--body-background-color, #fff);
  color: var(--body-color, #000);

  h1 {
    font-size: 2rem;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
    font-size: 0.9rem;
    font-weight: 300;
  }
  .contact-details {
    font-size: 0.8rem;

    a {
      color: var(--colors-primary-color, #000);
    }
  }

  .logo {
    width: 10em;
    margin-bottom: 1em;
  }
`;
