import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

interface GlobalBrandStylesProps {
  brandId: string | null | undefined;
}

export const GlobalBrandStyles: React.FC<GlobalBrandStylesProps> = ({ brandId }) => {
  switch (brandId) {
    case 'privatmegleren':
      return null;
    case 'rele':
      return <ReleStyles />;
    default:
      return <DefaultStyles />;
  }
};

const ReleStyles = createGlobalStyle`
    .approve-button-outline {
        color: #fff !important;
        border-color: #fff !important;
    }
`;

const DefaultStyles = createGlobalStyle``;
