import { createGlobalStyle } from 'styled-components';

export const PrivatMeglerenFonts = createGlobalStyle`
    :root {
        --heading-font-family: 'DomaineDispCondRegular', sans-serif;
        --body-font-family: 'Avenir', sans-serif;
    }


    @font-face {
        font-family: 'DomaineDispCondRegular';
        src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineDisplayCondensed-Regular.otf');
        font-weight: 300;
    }
    @font-face {
        font-family: 'DomaineText-Regular';
        src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineText-Regular.otf'),
            format('opentype');
        font-weight: normal;
    }
    @font-face {
        font-family: 'Geograph-Regular';
        src: url('https://cdn.reeltime.no/pm_assets/fonts/Geograph-Regular.otf'),
            format('opentype');
        font-weight: normal;
    }
    @font-face {
        font-family: 'Geograph-Bold';
        src: url('https://cdn.reeltime.no/pm_assets/fonts/Geograph-Bold.otf'),
            format('opentype');
        font-weight: bold;
    }
    @font-face {
        font-family: 'Avenir';
        src: url('https://cdn.reeltime.no/pm_assets/fonts/Avenir.ttc'),
            format('opentype');
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: var(--heading-font-family) !important;
        letter-spacing: 0;
    }
    

    * {
        -webkit-font-smoothing: antialiased;
        font-family: var(--body-font-family); 
    }

`;
